// import { GET } from './rest.service';

import { DELETE, GET, POST, PUT } from './rest.service';

// export const getProducts = () => {
//   return GET('/products');
// };

export const authUser = (payload) => {
  return POST('/admin/auth/login', payload);
};

export const forgotPassword = (payload) => {
  return POST('/admin/auth/forgotPassword', {
    email: payload.email,
  });
};

export const forgotPassVerifyEmail = (payload) => {
  return POST('/admin/auth/forgotPassword/verifyOtp', {
    email: payload.email,
    otp: payload.otp,
  });
};

export const getCurrentUser = () => {
  return GET(`/admin/auth/me`);
};

export const resetPassword = (payload) => {
  return POST('/admin/auth/resetPassword', payload);
};

export const updateProfile = (payload) => {
  return PUT('/admin/auth/me', payload);
};

export const changePassword = (payload) => {
  return PUT(`/admin/auth/password`, payload);
};

export const getCompanies = (payload) => {
  return POST(`/admin/company/query`, payload);
};

export const getWithdrawals = (payload) => {
  return POST(`/admin/withdrawals/query`, payload);
};

export const getMarketers = (payload) => {
  return POST(`/admin/marketers/query`, payload);
};

export const deleteCompany = (payload) => {
  return PUT(`/admin/company/${payload.companyId}/delete`, payload);
};

export const updateCompany = (payload, id) => {
  return PUT(`/admin/company/${id}/info`, payload);
};

export const getCompany = (companyId) => {
  return GET(`/admin/company/${companyId}`);
};

export const getWithdrawalDetails = (withdrawalId) => {
  return GET(`/admin/withdrawals/${withdrawalId}`);
};

export const updateCompanyBalance = (payload, companyId) => {
  return PUT(`/admin/company/${companyId}/balance`, payload);
};

export const getMarketer = (marketerId) => {
  return GET(`/admin/marketers/${marketerId}`);
};
export const deleteMarketer = (marketerId) => {
  return DELETE(`/admin/marketers/${marketerId}`);
};
export const updateMarketer = (payload, marketerId) => {
  return PUT(`/admin/marketers/${marketerId}`, payload);
};

export const markAsPaid = (withdrawalId) => {
  return POST(`/admin/withdrawals/${withdrawalId}`);
};

export const cancelWithdrawal = (withdrawalId) => {
  return DELETE(`/admin/withdrawals/${withdrawalId}`);
};

export const getWithdrawalTotalPaid = () => {
  return GET(`/admin/withdrawals/totalPaid`);
};

export const editAddress = (payload, companyId) => {
  return PUT(`/admin/company/${companyId}/address`, payload);
};

export const completedAuction = (payload, companyId) => {
  return GET(`/admin/company/${companyId}/auctions/completed`, payload);
};

export const getJobById = (jobId) => {
  return GET(`/admin/job/${jobId}`);
};
// Customer

export const getCustomer = (payload) => {
  return POST(`/admin/customer/query`, payload);
};

export const getCustomerDetail = (comapnyId) => {
  return GET(`/admin/customer/${comapnyId}`);
};
export const deleteCustomer = (payload) => {
  return PUT(`/admin/customer/${payload.customerId}/delete`, payload);
};
export const updateCustomer = (payload, id) => {
  return PUT(`/admin/customer/${id}`, payload);
};

export const getCustomerPendingJobs = (comapnyId, payload) => {
  return GET(`/admin/customer/${comapnyId}/jobs/pending`, payload);
};
export const getCustomerOngoingJobs = (comapnyId, payload) => {
  return GET(`/admin/customer/${comapnyId}/auctions/ongoing`, payload);
};
export const getCustomerCompletedJobs = (comapnyId, payload) => {
  return GET(`/admin/customer/${comapnyId}/jobs/completed`, payload);
};

// country
export const getCountries = (payload) => {
  return POST(`/admin/country/query`, payload);
};

export const getAvailableCountries = (payload) => {
  return POST(`/admin/country/availables`, payload);
};

export const getCountryDetail = (comapnyId) => {
  return GET(`/admin/country/${comapnyId}`);
};

export const deleteCountry = (payload) => {
  return DELETE(`/admin/country/${payload.countryId}`, payload);
};

export const updateCountry = (id, payload) => {
  return PUT(`/admin/country/${id}`, payload);
};

export const addCountry = (payload) => {
  return POST(`/admin/country`, payload);
};

// admins
export const getAdminUsers = (payload) => {
  return POST(`/admin/users/query`, payload);
};

export const deleteAdmin = (payload) => {
  return DELETE(`/admin/users/${payload.adminId}`, payload);
};

export const addAdmin = (payload) => {
  return POST(`/admin/users`, payload);
};

export const updateAdmin = (id, payload) => {
  return PUT(`/admin/users/${id}`, payload);
};

// service
export const getService = (payload) => {
  return GET(`/admin/service`, payload);
};
export const addService = (payload) => {
  return POST(`/admin/service`, payload);
};

export const getSubcategories = (payload) => {
  return GET(`/admin/service/subcategory`, payload);
};

export const addSubcategory = (payload) => {
  return POST(`/admin/service/subcategory`, payload);
};

export const editSubcategory = (subcategoryId, payload) => {
  return PUT(`/admin/service/subcategory/${subcategoryId}`, payload);
};

export const deleteSubcategory = (subcategoryId, payload) => {
  return DELETE(`/admin/service/subcategory/${subcategoryId}`, payload);
};

export const updateService = (serviceId, payload) => {
  return PUT(`/admin/service/${serviceId}`, payload);
};

export const deleteService = (serviceId) => {
  return DELETE(`/admin/service/${serviceId}`);
};

export const getPendingRequests = (payload) => {
  return POST(`/admin/companyProfile/changes_requested`, payload);
};

export const approveCompany = (companyId) => {
  return PUT(`/admin/company/${companyId}/request/accept`);
};

export const rejectCompany = (companyId, payload) => {
  return PUT(`/admin/company/${companyId}/request/reject`, payload);
};

export const blockCompany = (companyId, payload) => {
  return PUT(`/admin/company/${companyId}/block`, payload);
};

export const unblockCompany = (companyId) => {
  return PUT(`/admin/company/${companyId}/unblock`);
};

export const getJobQuery = (payload) => {
  return POST(`/admin/job/query`, payload);
};

export const deleteJob = (payload) => {
  return PUT(`/admin/job/${payload.jobId}/delete`, payload);
};

export const startAuctionForJob = (payload) => {
  return POST(`/admin/job/${payload.jobId}/auction-start`, payload);
};

export const cancelAuctionForJob = (payload) => {
  return POST(`/admin/job/${payload.jobId}/auction-cancel`, payload);
};

export const restartAuctionForJob = (payload) => {
  return POST(`/admin/job/${payload.jobId}/auction-restart`, payload);
};

export const getAllServices = (payload) => {
  return GET(`/admin/service`, payload);
};

export const updateJobById = (jobId, payload) => {
  return PUT(`/admin/job/${jobId}`, payload);
};

export const getMembership = (payload) => {
  return POST(`/admin/subscription/query`, payload);
};

export const updateSubscription = (subscriptionId, payload) => {
  return PUT(`/admin/subscription/${subscriptionId}`, payload);
};

export const getAllSubscription = () => {
  return GET('/admin/subscription');
};

// Notification

export const getNotification = (payload) => {
  return GET(`/admin/notification`, payload);
};

export const getRevenueStatistics = (payload) => {
  return POST(`/admin/dashboard/revenue`, payload);
};

export const getDatabaseStatistics = (payload) => {
  return POST(`/admin/dashboard/database`, payload);
};

export const getDashboardCounters = () => {
  return GET(`/admin/dashboard/counters`);
};

export const getProjectNames = () => {
  return GET(`/public/projectNames`);
};

export const profileList = (payload) => {
  return POST(`/admin/companyProfile/query`, payload);
};

export const profileDetail = (id) => {
  return GET(`/admin/companyProfile/${id}`);
};

export const profileChangesDetail = (id) => {
  return GET(`/admin/companyProfile/request/${id}`);
};

export const profileChangesApprove = (id) => {
  return PUT(`/admin/companyProfile/${id}/changes_requested/accept`);
};

export const profileChangesDecline = (id) => {
  return PUT(`/admin/companyProfile/${id}/changes_requested/reject`);
};

export const profileApprove = (id) => {
  return PUT(`/admin/companyProfile/${id}/request/accept`);
};

export const profileDecline = (id) => {
  return PUT(`/admin/companyProfile/${id}/request/reject`);
};
